import React, { useMemo } from 'react';
import { styled } from '@material-ui/core/styles';
import { Card, CardMedia, CardActionArea, Box } from '@material-ui/core';
import * as AspectRatioPrimitive from '@radix-ui/react-aspect-ratio';
import missingImageDefault from '../../static/placeholder/product_image.jpg';
import { MLazyLoadImage } from '../core/@react-lazy-load-image-component-extend';
import { varFadeIn, MotionInView } from '../core/animate';

export const AspectRatio = AspectRatioPrimitive;

const Root = styled(Box)(() => ({
  position: 'relative',
  cursor: 'pointer',
  padding: 0
}));

const CardStyle = styled(Card)(() => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  borderRadius: 0
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusSm
}));

const StyledCardActionArea = styled(CardActionArea)(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusSm
}));

export const GalleryImageItem = ({ showImagePreview, item, handleClick }) => {
  const { desktopImage } = item;

  const ImageContentMemo = useMemo(
    () => (
      <AspectRatio.Root ratio={1 / 1}>
        <StyledCardMedia
          component={MLazyLoadImage}
          source={desktopImage || missingImageDefault}
          imgSx={{
            height: '100%',
            width: '100%'
          }}
          sx={{ height: '100%', width: '100%', borderRadius: (theme) => theme.shape.borderRadiusSm }}
        />
      </AspectRatio.Root>
    ),
    [desktopImage]
  );

  return (
    <Root>
      <MotionInView variants={varFadeIn}>
        <CardStyle
          {...(showImagePreview && { onClick: () => handleClick(desktopImage) })}
          sx={{ ...(!showImagePreview && { cursor: 'default' }) }}
        >
          {showImagePreview ? <StyledCardActionArea>{ImageContentMemo}</StyledCardActionArea> : ImageContentMemo}
        </CardStyle>
      </MotionInView>
    </Root>
  );
};
