import React, { useMemo } from 'react';
import { styled } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import * as AspectRatioPrimitive from '@radix-ui/react-aspect-ratio';
import { defaultTo } from 'lodash';
import { getHorizontalPosition, getVerticalPosition } from '../../utils/getPositions';
import useMobile from '../../hooks/useMobile';
import { MLazyLoadImage } from '../core/@react-lazy-load-image-component-extend';
import { varFadeIn, MotionInView } from '../core/animate';
import placeholderImage from '../../static/placeholder/Image_and_text.jpg';
import LayoutOrientation from '../core/LayoutOrientation';
import { getSelectedImage } from '../../utils/getSelectedImage';
import { SimpleTextBlock } from '../core/SimpleTextBlock';
import { createOverlayWithSpread } from '../../utils/createOverlay';
import { gridSizing } from '../../utils/grid';
import { isInvalidText } from '../../utils/nullable';

export const AspectRatio = AspectRatioPrimitive;

const HEIGHT_CONSTRAINT_STYLE = {
  minHeight: '400px'
};

const RootImageStyle = styled(Box)(() => ({
  position: 'relative',
  height: '100%'
}));

const RootContentStyle = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(10),
  height: '100%'
}));

const ImageStyle = styled(MLazyLoadImage)(() => ({
  width: '100%',
  height: '100%',
  ...HEIGHT_CONSTRAINT_STYLE
}));

const BackgroundOverlay = styled(Box)(() => ({
  top: 0,
  zIndex: 2,
  width: '100%',
  height: '100%',
  position: 'absolute'
}));

function VerticalImageAndText({ component, handleNavigationClick = () => {} }) {
  const isMobile = useMobile();

  const VerticalImageAndTextMemo = useMemo(() => {
    const {
      title,
      contentPosition,
      showOverlay,
      overlayDirection,
      overlaySpread,
      overlayColourFirst: overlayColourFirstRoot,
      overlayColourSecond: overlayColourSecondRoot,
      desktopImage,
      mobileImage,
      navigation,
      backgroundColour,
      switchDesktopOrientation
    } = component;

    const img = getSelectedImage(isMobile, desktopImage, mobileImage);

    return (
      <Box sx={{ ...(backgroundColour && { backgroundColor: backgroundColour }) }}>
        <Grid container direction={switchDesktopOrientation ? 'row-reverse' : 'row'}>
          <Grid item {...gridSizing(isMobile)}>
            <RootContentStyle sx={{ ...(isMobile && { padding: (theme) => theme.spacing(3, 3, 8) }) }}>
              <LayoutOrientation
                verticalPosition={getVerticalPosition(contentPosition)}
                horizontalPosition={getHorizontalPosition(contentPosition)}
              >
                <SimpleTextBlock
                  {...component}
                  position={getHorizontalPosition(contentPosition)}
                  onButtonClick={() => handleNavigationClick(navigation?.type, navigation?.path)}
                />
              </LayoutOrientation>
            </RootContentStyle>
          </Grid>
          <Grid item {...gridSizing(isMobile)}>
            <RootImageStyle>
              <MotionInView variants={varFadeIn} sx={{ height: '100%' }}>
                <ImageStyle alt={defaultTo(title, '')} src={img || placeholderImage} imgSx={{ borderRadius: 0 }} />
              </MotionInView>

              <BackgroundOverlay
                sx={{
                  ...(showOverlay && {
                    backgroundImage: (theme) =>
                      createOverlayWithSpread(
                        overlayDirection,
                        overlaySpread,
                        isInvalidText(overlayColourFirstRoot) ? theme.palette.grey[900] : overlayColourFirstRoot,
                        isInvalidText(overlayColourSecondRoot) ? theme.palette.grey[900] : overlayColourSecondRoot
                      )
                  })
                }}
              />
            </RootImageStyle>
          </Grid>
        </Grid>
      </Box>
    );
  }, [isMobile, component, handleNavigationClick]);

  return VerticalImageAndTextMemo;
}

export default VerticalImageAndText;
