import React, { useMemo } from 'react';
import { Stack, Box, Container } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { styled } from '@material-ui/core/styles';
import useMobile from '../../hooks/useMobile';
import { isInvalidText } from '../../utils/nullable';
import Markdown from '../core/Markdown';
import {
  MOBILE_STACK_SPACING,
  DESKTOP_CONTAINER_TOP_BOTTOM_PADDING,
  MOBILE_CONTAINER_TOP_BOTTOM_PADDING
} from '../../utils/constants';
import { StandardPageHeading } from '../core/StandardPageHeading';

const Content = styled(Stack)(({ theme }) => ({
  paddingTop: theme.spacing(DESKTOP_CONTAINER_TOP_BOTTOM_PADDING),
  paddingBottom: theme.spacing(DESKTOP_CONTAINER_TOP_BOTTOM_PADDING)
}));

function SimpleBodyText({ component }) {
  const isMobile = useMobile();
  const title = component?.title;
  const body = component?.body;
  const showTitle = component?.showTitle;
  const titleColour = component?.titleColour;
  const widthView = component?.widthView;
  const backgroundColour = component?.backgroundColour;

  const SimpleBodyTextMemo = useMemo(() => {
    const stackSpacing = isMobile ? MOBILE_STACK_SPACING : 4;
    const isNarrowView = !isMobile && widthView === 'STANDARD';

    return (
      <Content
        spacing={stackSpacing}
        direction="column"
        alignItems="center"
        sx={{
          backgroundColor: (theme) =>
            isInvalidText(backgroundColour) ? theme.palette.background.paper : backgroundColour,
          ...(isMobile && {
            paddingTop: (theme) => theme.spacing(MOBILE_CONTAINER_TOP_BOTTOM_PADDING),
            paddingBottom: (theme) => theme.spacing(MOBILE_CONTAINER_TOP_BOTTOM_PADDING)
          })
        }}
      >
        {showTitle && <StandardPageHeading title={title} titleColour={titleColour} />}
        <Container maxWidth="xl" sx={{ display: 'flex', justifyContent: 'center' }}>
          {!isEmpty(body) && (
            <Box
              sx={{
                width: '100%',
                ...(isNarrowView && { width: 680 })
              }}
            >
              <Markdown children={body} />
            </Box>
          )}
        </Container>
      </Content>
    );
  }, [isMobile, title, body, showTitle, titleColour, backgroundColour, widthView]);

  return SimpleBodyTextMemo;
}

export default SimpleBodyText;
